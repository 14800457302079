<template>
    <div class="h-full">
        <fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
            @onPageChange="onPageChange" :queryParam="queryParam">
            <div slot="buttonGroup" class="h-handle-button">
                <div class="h-b">
                    <el-button type="primary" size="small" @click="addProject"
                        v-right-code="'Projectinfo:Getempty'">新增</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="enable"
                        v-right-code="'Projectinfo:Batchdisable'">启用</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="disable"
                        v-right-code="'Projectinfo:Batchdisable'">停用</el-button>
                </div>
                <div class="h-b">
                    <el-button type="primary" size="small" @click="del"
                        v-right-code="'Projectinfo:Delete'">删除</el-button>
                </div>
            </div>
            <p slot="elList">
                <el-table ref="projectListTable" :data="dataSource.Result" border
                    @selection-change="handleSelectionChange" highlight-current-row>
                    <el-table-column type="selection" width="55" v-if="!config.isDetailDisplay"></el-table-column>
                    <el-table-column v-for="(col, index, count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
                        :prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam, col)"
                        :fixed="index == 0 && (!config || !config.isDetailDisplay)"
                        :min-width="(!config || !config.isDetailDisplay) ? parseInt(col.Width) : 0"
                        v-if="col.Visible && (!config || !config.isDetailDisplay || (config.isDetailDisplay && index < 2))">
                        <template slot-scope="scope">
                            <span v-if="col.FieldName === 'Code'"><span type="text" @click="rowClick(scope.row, false)"
                                    style="text-decoration:underline;color:#1874CD;cursor:pointer">{{
            scope.row[col.FieldName] }}</span></span>
                            <span v-else> {{ scope.row[col.FieldName] }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" :min-width="80" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" v-right-code="'Projectinfo:Edit'"
                                style="margin-left:10px;" @click="editClick(scope.row, true)">编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </p>
        </fixed-list>
    </div>
</template>

<script>
export default {
    mounted() {
        this.Event.$on("reloadProjectList", () => this.reloadPageList());
        this.Event.$on("onAddProject", () => this.addProject());
        this.initialize();
    },
    data() {
        return {
            multipleSelection: [],
            queryParam: {
                PageIndex: 1,
                PageSize: 10,
            },
            dataSource: {
                ColDefs: {},
                Result: [],
                TotalCount: 0
            },

        }
    },
    props: {
        config: {
            isDetailDisplay: false,
            isButtonlDisplay: false,
        },
        option: {},
    },
    methods: {
        enable() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择启用的项目.`, "error");
                return false;
            }

            this.$ajax.send("omsapi/projectinfo/batchenable", "post", this.multipleSelection, (data) => {
                this.onPageChange(this.queryParam);
                this.Utils.messageBox("项目启用成功.", "success");
            });
        },
        disable() {
            if (this.multipleSelection == null || this.multipleSelection.length == 0) {
                this.Utils.messageBox(`未选择停用项目.`, "error");
                return false;
            }

            this.$ajax.send("omsapi/projectinfo/batchdisable", "post", this.multipleSelection, (data) => {
                this.onPageChange(this.queryParam);
                this.Utils.messageBox("项目停用成功.", "success");
            });
        },
        del() {
            if (
                this.multipleSelection == null ||
                this.multipleSelection.length == 0
            ) {
                this.Utils.messageBox(`未选择要删除的数据项.`, "error");
                return false;
            }
            var This = this;
            this.Utils.confirm(
                {
                    title: "是否确认删除数据项?",
                    content: "删除后数据不可恢复,该数据项将不可使用,是否确认删除?",
                },
                () => {
                    var ids = this.Utils.selectionsToArr(this.multipleSelection, "Id");

                    this.$ajax.send(
                        "omsapi/projectinfo/delete",
                        "post",
                        ids,
                        (data) => {
                            This.onPageChange(This.queryParam);
                            This.Utils.messageBox("数据项删除成功.", "success");
                        }
                    );
                },
                () => {
                    This.Utils.messageBox("已取消删除.", "info");
                }
            );
        },
        searchProject: function () {
            this.onPageChange(this.queryParam);
        },
        reloadPageList: function () {
            this.initialize();
        },
        initialize() {
            this.onPageChange(this.queryParam);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
            this.$emit("onCheckRow", this.multipleSelection);
        },
        onPageChange(param) {
            console.log(111);
            var _this = this;
            this.queryParam = param;
            this.$ajax.query("omsapi/projectinfo/getpagelist", "post", this.queryParam, data => {
                _this.dataSource = data;
            });
        },
        onDataSourceChange(ds) {
            var _this = this;

            _this.dataSource = {
                ColDefs: {
                    BodyFieldParams: []
                },
                Result: [],
                TotalCount: 0
            };
            _this.$nextTick(function () {
                _this.dataSource = ds;

            });

        },
        rowClick(row, isEdit) {
            // this.$ajax.send("omsapi/projectinfo/getbyid", "get", {
            //     id: row.Id
            // }, (data) => {
            //     this.Event.$emit("clearEditProjectForm");
            //     data.Result.IsEdit = isEdit;
            //     this.onChangeEditDataSource(data.Result);
            // });
            this.onChangeDetailDataSource(row.Id);
        },
        editClick(row) {
            this.onChangeEditDataSource(row.Id);
        },
        addProject() {
            this.onChangeEditDataSource(null);
        },
        onChangeEditDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeEditDataSource", data);
        },
        onChangeDetailDataSource(data) {
            this.config.isDetailDisplay = true;
            this.$emit("onChangeDetailDataSource", data);
        },
    },
}
</script>

<style></style>
